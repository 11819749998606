import { Module } from 'core';
import { HomePage } from './page';

export function setup(root: Module) {

    root.route({
        path: '/',
        exact: true,
        component: HomePage,
    });
}