import React from 'react';
import { Link } from 'react-router-dom';
import { getConfig } from 'config';
import styled from 'styled-components';

const BranchName = styled.div`
    padding: 12px
`;

export function NavBar() {
    return (
        <div>
            <Link to="/" style={{ textDecoration: 'None' }}>
                <BranchName>
                    {`${getConfig('BRANCH_NAME')}`}
                </BranchName>
            </Link>
        </div>
    );
}
