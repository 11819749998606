import React, { lazy, Suspense } from 'react';

const LazyIntroPage = lazy(() => import('./intro-page'));
const LazyProjectPage = lazy(() => import('./project-page'));

export function HomePage() {
    return (
        <Suspense fallback={''}>
            <LazyIntroPage />
            <LazyProjectPage />
        </Suspense>
    );
}
