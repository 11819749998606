import React from 'react';
import { getConfig } from 'config';
import styled from 'styled-components';

const FooterContainer = styled.div`
    background-color: white;
    padding: 0.5rem;
`;


export function Footer() {
    return (
        <FooterContainer>
            Copyright © {(new Date()).getFullYear()} <strong>{getConfig('BRANCH_NAME')}</strong>.
        </FooterContainer>
    );
}
