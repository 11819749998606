import React from 'react';
import styled from 'styled-components';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    min-height: 100vh;
`;

export function AppWrapper({ children }: any) {
    return (
        <AppContainer className="app-wrap">
            <div className="body">
                {children}
            </div>
        </AppContainer>
    );
}
